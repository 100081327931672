import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import App from "./App.vue"
import EventBus from "./services/event-bus"
import Api from "./services/api"

// Vcalendar
import VCalendar from "v-calendar"
import "v-calendar/dist/style.css"

// Maska (input mask)
import Maska from "maska"

// Tabler UI
import "@tabler/core/dist/css/tabler.css"
import "@tabler/core/dist/js/tabler"
import "./assets/styles.css"

// pages
import DashboardPage from "./pages/DashboardPage.vue"
import LoginPage from "./pages/LoginPage.vue"
import LoginAsCustomerPage from "./pages/LoginAsCustomerPage.vue"
import UserProfilePage from "./pages/UserProfile.vue"
import WorkspaceListPage from "./pages/WorkspaceListPage.vue"
import UsersPage from "./pages/UsersPage.vue"
import RecoveryPasswordPage from "./pages/RecoveryPasswordPage.vue"
import RecoveryPasswordChangePage from "./pages/RecoveryPasswordChangePage.vue"
// import AccountStatusPage from './pages/AccountStatusPage.vue';
import CompanyPage from "./pages/CompanyPage.vue"
import AccountPage from "./pages/AccountPage.vue"
import Oauth2CallbackPage from "./pages/Oauth2CallbackPage.vue"

import { store } from "./store"

const routes = [
    { path: "/login-as-customer", component: LoginAsCustomerPage },
    { path: "/", component: DashboardPage, beforeEnter: checkLogin },
    // { path: '/', redirect: '/workspaces', beforeEnter: checkLogin },
    { path: "/login", component: LoginPage },
    { path: "/recovery_password", component: RecoveryPasswordPage },
    {
        path: "/recovery_password/:token/:email",
        component: RecoveryPasswordChangePage
    },
    {
        path: "/user/profile",
        component: UserProfilePage,
        beforeEnter: checkLogin
    },
    {
        path: "/user/logout",
        beforeEnter: () => {
            EventBus.emit("call-logout")
        }
    },
    {
        path: "/workspaces",
        component: WorkspaceListPage,
        beforeEnter: checkLogin
    },
    { path: "/users", component: UsersPage, beforeEnter: checkLogin },
    { path: "/account", component: AccountPage, beforeEnter: checkLogin },
    {
        path: "/account/company",
        component: CompanyPage,
        beforeEnter: checkLogin
    },
    {
        path: "/oauth2/callback/:auth_type",
        component: Oauth2CallbackPage,
        beforeEnter: checkLogin
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

function checkLogin(to, from, next) {
    if (!localStorage.getItem("token")) {
        return router.push("/login")
    }

    if (
        localStorage.getItem("token") &&
        localStorage.getItem("tokenExpiration")
    ) {
        let tokenExpiration = localStorage.getItem("tokenExpiration")
        if (tokenExpiration <= Date.now()) {
            return router.push("/login")
        }
    }

    return next()
}

// global events
EventBus.on("call-logout", () => {
    localStorage.removeItem("loginAsCustomer")
    localStorage.removeItem("adminUserId")
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("tokenExpiration")
    localStorage.removeItem("workspace")
    router.push("/login")
})

EventBus.on("updated-user", (userData) => {
    store.user = userData
    localStorage.setItem("user", JSON.stringify(userData))
})

// initialize store data
store.user = JSON.parse(localStorage.getItem("user"))

// clear messages on router change
router.beforeEach((to, from, next) => {
    store.showBackButton = false
    store.backUrl = null
    store.sidebarSize = "full"
    next()
})

// apply body class when ajax request is running
EventBus.on("ajax-request-start", () => {
    document.body.classList.add("ajax-request")
})

EventBus.on("ajax-request-end", () => {
    setTimeout(() => {
        document.body.classList.remove("ajax-request")
    }, 200)
})

// register token expiration verification at every 5 minutes
setInterval(() => {
    let tokenExpiration = localStorage.getItem("tokenExpiration")
    if (tokenExpiration) {
        // if token is about to expire (15 min before), send a refresh token request
        if (tokenExpiration <= Date.now() + 60 * 1000 * 15) {
            console.log("refreshing expirated token...")
            Api.refreshToken().then(async (response) => {
                let data = JSON.parse(atob(response.data.token.split(".")[1]))
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("tokenExpiration", data.exp * 1000)

                var userResponse = await Api.users.me()
                EventBus.emit("updated-user", userResponse.data)

                console.log("all done!")
            })
        }
    }
}, 1000 * 60) // every 1 minute

var app = createApp(App)

app.use(VCalendar, {})
app.use(Maska)
app.use(router)
app.mount("#app")
